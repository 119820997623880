import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import ToursResult from './ToursResult';

function ToursResultFunc(props) {
  const { countryFilter } = props;

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ToursResult countryFilter={countryFilter} location={location} navigate={navigate} />
  );
}

ToursResultFunc.propTypes = {
  countryFilter: PropTypes.string,
};

ToursResultFunc.defaultProps = {
  countryFilter: '',
};

export default ToursResultFunc;
