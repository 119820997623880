/* eslint-disable eqeqeq */
/* eslint-disable react/self-closing-comp */
/* eslint-disable comma-dangle */
/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { Bars, FallingLines } from 'react-loader-spinner';
import FlightRoundTrip from '../FlightRoundTrip/FlightRoundTrip';
import { getTourPrice, submitOrder, getSystemSettings } from '../../Utils/RestUtils';
import Input from '../Input/Input';
import Timer from '../Timer/Timer';
import {
  FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, CITIZENSHIP, PASSPORT, EMAIL, PHONE, SEX,
  PASSPORT_DATE_START, PASSPORT_DATE_END, PASSPORT_IDENTIFICATION, AFTER_NANE,
  FIRST_NAME_PH, LAST_NAME_PH, AFTER_NAME_PH, DATE_PH, PASSPORT_PH, RUS_LETTERS_ERROR,
  FIRST_NAME_LAT, LAST_NAME_LAT, LAT_LETTERS_ERROR, CITIZENSHIP_PH, MALE_SEX, FEMALE_SEX,
  PRICE_ACTUALIZATION,
  // CARD_NUMBER, CARD_NUMBER_PH, ONLY_NUMBERS_ERROR, CARD_TERM,
  // CARD_TERM_MONTH_PH, CARD_TERM_YEAR_PH, CARD_HOLDER_NAME, CARD_HOLDER_NAME_PH, CARD_CVV,
} from '../../Utils/Constants/CheckoutConstants';
import {
  onlyRusLetters, passportSeriaAndNumber, onlyLatLetters,
  // cardNumberValidation, onlyNumbers,
} from '../../Utils/ValidationUtil';
import { getTouristsText, getDateAndNightsText } from '../../Utils/CommonUtils';
import { changeTimeIsUpVisibility } from '../../DataStore/actions/searchFormActions';
// import mastercard from '../../images/mastercard.svg';
import starIco from '../../images/star.svg';
import { CHECKOUT_TITLE } from '../../Utils/Constants/SEO';
import './css/Checkout.css';

const mapStateToProps = (state) => ({ ...state });

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    const { location } = this.props;

    const tourists = this.fillTourists();

    this.state = {
      sellOnStop: false,
      paymentTest: false,
      isContentDisabled: false,
      priceData: location.state.priceData,
      currentTime: '',
      timerReset: null,
      contractInfo: {
        lastName: '',
        lastNameValid: false,
        firstName: '',
        firstNameValid: false,
        afterName: '',
        afterNameValid: false,
        birthDay: '',
        birthDayValid: false,
        passport: '',
        passportValid: false,
        passportStartDate: '',
        passportStartDateValid: false,
        passportEndDate: '',
        passportEndDateValid: false,
        sex: '',
        sexValid: false,
      },
      // creditCard: {
      //   number: '',
      //   numberValid: false,
      //   month: '',
      //   monthValid: false,
      //   year: '',
      //   yearValid: false,
      //   holder: '',
      //   holderValid: false,
      //   cvv: '',
      //   cvvValid: false,
      // },
      tourists,
      insurance: false,
      isSubmitSpinnerVisible: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    getSystemSettings().then((result) => {
      if (result && result.data && result.data.length > 0) {
        const { sellOnStop, paymentTest } = result.data[0];
        if (sellOnStop === true) {
          this.setState({ sellOnStop: true });
        }

        if (paymentTest === true) {
          this.setState({ paymentTest: true });
        }
      }
    }).catch();
  }

  fillTourists = () => {
    const { location } = this.props;
    const { adults, kids } = location.state;
    const totalCount = parseInt(adults, 10) + parseInt(kids, 10);

    const tourists = [];
    for (let i = 0; i < totalCount; i++) {
      const tourist = {
        id: '',
        lastNameUpper: '',
        lastNameUpperValid: false,
        firstNameUpper: '',
        firstNameUpperValid: false,
        birthDay: '',
        birthDayValid: false,
        sitizenship: 'Беларусь',
        sitizenshipValid: true,
        passport: '',
        passportValid: false,
        passportStartDate: '',
        passportStartDateValid: false,
        passportEndDate: '',
        passportEndDateValid: false,
        identity: '',
        identityValid: false,
        sex: '',
        sexValid: false,
      };

      tourist.id = i;
      tourists.push(tourist);
    }

    return tourists;
  };

  renderHotelCategory = (starsCount) => {
    const content = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < starsCount; i++) {
      content.push(<img alt="" key={`hd-stars-${i}`} className="tr_category_star" src={starIco} />);
    }
    return content;
  };

  renderTuristsDataForm = () => {
    const content = [];
    const { tourists } = this.state;

    for (let i = 0; i < tourists.length; i++) {
      const tourist = tourists[i];

      content.push(
        <div className="ch_tourst_data_w" key={`tourist-data-${i}`}>
          <div className="ch_tourist_data_title">{`Турист номер ${i + 1}`}</div>
          <div className="ch_inputs_w">
            <Input
              id={i}
              name="lastNameUpper"
              label={LAST_NAME}
              placeholder={FIRST_NAME_LAT}
              value={tourist.lastNameUpper}
              handleValueChange={this.handleTouristChange}
              validation={onlyLatLetters}
              validationError={LAT_LETTERS_ERROR}
              isValid={tourist.lastNameUpperValid}
              isValidChange={this.handleTouristValid}
              required
            />
            <Input
              id={i}
              name="firstNameUpper"
              label={FIRST_NAME}
              placeholder={LAST_NAME_LAT}
              value={tourist.firstNameUpper}
              handleValueChange={this.handleTouristChange}
              validation={onlyLatLetters}
              validationError={LAT_LETTERS_ERROR}
              isValid={tourist.firstNameUpperValid}
              isValidChange={this.handleTouristValid}
              required
            />
            <Input
              id={i}
              name="birthDay"
              label={DATE_OF_BIRTH}
              placeholder={DATE_PH}
              value={tourist.birthDay}
              handleValueChange={this.handleTouristChange}
              type="date"
              isValid={tourist.birthDayValid}
              isValidChange={this.handleTouristValid}
              required
            />
            <div className="ch_input_wrapper">
              <div>
                {SEX}
                &nbsp;
                <span className="ch_input_required">*</span>
              </div>
              <div className="ch_sex_slector_w">
                <button
                  type="button"
                  value="male"
                  className={`ch_sex_button_firsr ${!tourist.sex ? 'ch_sex_btn_firt' : ''} ${tourist.sex === 'male' ? 'ch_sex_selected' : ''}`}
                  onClick={() => this.handleTouristSexSelector('male', i)}
                >
                  {MALE_SEX}
                </button>
                <button
                  type="button"
                  value="female"
                  className={`ch_sex_button_sec ${tourist.sex === 'female' ? 'ch_sex_selected' : ''}`}
                  onClick={() => this.handleTouristSexSelector('female', i)}
                >
                  {FEMALE_SEX}
                </button>
              </div>
            </div>
          </div>
          <div className="ch_inputs_w">
            <Input
              id={i}
              name="sitizenship"
              label={CITIZENSHIP}
              placeholder={CITIZENSHIP_PH}
              value={tourist.sitizenship}
              handleValueChange={this.handleTouristChange}
              validation={onlyRusLetters}
              validationError={RUS_LETTERS_ERROR}
              isValid={tourist.sitizenshipValid}
              isValidChange={this.handleTouristValid}
              required
            />
            <Input
              id={i}
              name="passport"
              label={PASSPORT}
              placeholder={PASSPORT_PH}
              value={tourist.passport}
              handleValueChange={this.handleTouristChange}
              validation={passportSeriaAndNumber}
              validationError={LAT_LETTERS_ERROR}
              isValid={tourist.passportValid}
              isValidChange={this.handleTouristValid}
              maxLength={9}
              required
            />
            <Input
              id={i}
              label={PASSPORT_DATE_START}
              name="passportStartDate"
              placeholder={DATE_PH}
              type="date"
              value={tourist.passportStartDate}
              handleValueChange={this.handleTouristChange}
              isValid={tourist.passportStartDateValid}
              isValidChange={this.handleTouristValid}
              required
            />
            <Input
              id={i}
              label={PASSPORT_DATE_END}
              name="passportEndDate"
              placeholder={DATE_PH}
              type="date"
              value={tourist.passportEndDate}
              handleValueChange={this.handleTouristChange}
              isValid={tourist.passportEndDateValid}
              isValidChange={this.handleTouristValid}
              required
            />
            <Input
              id={i}
              label={PASSPORT_IDENTIFICATION}
              name="identity"
              value={tourist.identity}
              handleValueChange={this.handleTouristChange}
              validation={passportSeriaAndNumber}
              validationError={LAT_LETTERS_ERROR}
              isValid={tourist.identityValid}
              isValidChange={this.handleTouristValid}
              required
            />
          </div>
        </div>
      );
    }

    return content;
  };

  handleContractChange = (name, value) => {
    const { contractInfo } = this.state;
    const validFieldName = `${name}Valid`;
    this.setState(
      { contractInfo: { ...contractInfo, [name]: value, [validFieldName]: true } }
    );
  };

  handleContractValid = (name, value) => {
    const { contractInfo } = this.state;
    const fieldName = `${name}Valid`;
    this.setState({ contractInfo: { ...contractInfo, [fieldName]: value } });
  };

  handleContrakctSexSelector = (value) => {
    const { contractInfo } = this.state;
    this.setState({ contractInfo: { ...contractInfo, sex: value, sexValid: true } });
  };

  handleTouristSexSelector = (value, id) => {
    const { tourists } = this.state;

    const newState = tourists.map((tourist) => {
      // eslint-disable-next-line eqeqeq
      if (tourist.id == id) {
        return { ...tourist, sex: value, sexValid: true };
      }

      return tourist;
    });

    this.setState({ tourists: newState });
    // this.setState({ contractInfo: { ...contractInfo, sex: value } });
  };

  handleTouristChange = (name, value, id) => {
    const { tourists } = this.state;
    const validFieldName = `${name}Valid`;

    const newState = tourists.map((tourist) => {
      // eslint-disable-next-line eqeqeq
      if (tourist.id == id) {
        return { ...tourist, [name]: value, [validFieldName]: true };
      }

      return tourist;
    });

    this.setState({ tourists: newState });
  };

  handleTouristValid = (name, value, id) => {
    const { tourists } = this.state;

    const fieldName = `${name}Valid`;

    const newState = tourists.map((tourist) => {
      // eslint-disable-next-line eqeqeq
      if (tourist.id == id) {
        return { ...tourist, [fieldName]: value };
      }

      return tourist;
    });

    this.setState({ tourists: newState });
  };

  // handleCreditCardChange = (name, value) => {
  //   const { creditCard } = this.state;
  //   const validFieldName = `${name}Valid`;

  //   this.setState({ creditCard: { ...creditCard, [name]: value, [validFieldName]: true } });
  // };

  // handleCreditCardValid = (name, value) => {
  //   const { creditCard } = this.state;
  //   const fieldName = `${name}Valid`;

  //   this.setState({ creditCard: { ...creditCard, [fieldName]: value } });
  // };

  // eslint-disable-next-line arrow-body-style
  isPayDisabled = () => {
    const { sellOnStop } = this.state;

    return !(this.isContractValid()
      && this.isTouristsDataValid()
      /* && this.isCreditCardValid() */
      && !sellOnStop);
  };

  isContractValid = () => {
    const { contractInfo } = this.state;

    return contractInfo.lastNameValid
      && contractInfo.firstNameValid
      && contractInfo.afterNameValid
      && contractInfo.birthDayValid
      && contractInfo.passportValid
      && contractInfo.passportStartDateValid
      && contractInfo.passportEndDateValid
      && contractInfo.sexValid;
  };

  isTouristsDataValid = () => {
    const { tourists } = this.state;

    for (let i = 0; i < tourists.length; i++) {
      const tourist = tourists[i];
      if (!(tourist.lastNameUpperValid
          && tourist.lastNameUpperValid
          && tourist.firstNameUpperValid
          && tourist.birthDayValid
          && tourist.sitizenshipValid
          && tourist.passportValid
          && tourist.passportStartDateValid
          && tourist.passportEndDateValid
          && tourist.identityValid
          && tourist.sexValid)) {
        return false;
      }
    }

    return true;
  };

  // isCreditCardValid = () => {
  //   const { creditCard } = this.state;

  //   return creditCard.numberValid
  //     && creditCard.monthValid
  //     && creditCard.yearValid
  //     && creditCard.holderValid
  //     && creditCard.cvvValid;
  // };

  timeIsUp = (callBack) => {
    const { changeTimeIsUpVisibility: changeTimeIsUpVisibilityAction } = this.props;
    changeTimeIsUpVisibilityAction(true);

    this.topRef.scrollIntoView({ behavior: 'smooth' });

    this.setState({ timerReset: callBack });
  };

  onModalButtonClick = () => {
    const { changeTimeIsUpVisibility: changeTimeIsUpVisibilityAction, location } = this.props;
    const { tourPricePayload } = location.state;
    const { timerReset } = this.state;

    changeTimeIsUpVisibilityAction(false);
    this.setState({ isContentDisabled: true });

    getTourPrice(tourPricePayload).then((result) => {
      this.setState({
        priceData: result.data,
      });
    }).finally(() => {
      this.setState({ isContentDisabled: false });
      timerReset();
    });
  };

  currentTimeUpdate = (time) => {
    this.setState({ currentTime: time });
  };

  toggleCheckbox = () => {
    const { insurance } = this.state;
    this.setState({ insurance: !insurance });
  };

  handleSubmitOrder = (btnRef) => {
    btnRef.current.setAttribute('disabled', 'disabled');

    this.setState({ isSubmitSpinnerVisible: true });
    const {
      contractInfo, tourists, priceData, insurance, paymentTest,
    } = this.state;

    const { location } = this.props;
    const { adults, kids } = location.state;

    const {
      email, phone, selectedRoundTrip, hotelId,
    } = location.state;

    const touristsPayload = [];

    for (let i = 0; i < tourists.length; i++) {
      const t = tourists[i];

      const tourist = {
        lastNameUpper: t.lastNameUpper,
        firstNameUpper: t.firstNameUpper,
        birthDay: t.birthDay,
        sex: t.sex,
        citizenship: t.sitizenship,
        passport: t.passport,
        passportStartDate: t.passportStartDate,
        passportEndDate: t.passportEndDate,
        identity: t.identity,
      };

      touristsPayload.push(tourist);
    }

    const contactInfo = {
      firstName: contractInfo.firstName,
      lastName: contractInfo.lastName,
      afterName: contractInfo.afterName,
      birthDay: contractInfo.birthDay,
      passport: contractInfo.passport,
      passportStartDate: contractInfo.passportStartDate,
      passportEndDate: contractInfo.passportEndDate,
      sex: contractInfo.sex,
    };

    const payload = {
      phoneNumber: phone,
      email,
      contactInfo,
      tourists: touristsPayload,

      operator: priceData.operator,
      originalPrice: priceData.originalPrice,
      totalPriceByn: priceData.totalPriceByn,
      totalPriceAlfa: paymentTest ? '100' : priceData.totalPriceAlfa,
      currency: priceData.currency,

      departureCity: priceData.departureCity,
      countryTo: priceData.countryTo,
      resort: priceData.resort,
      program: priceData.program,

      hotelId,
      hotelName: priceData.hotelName,
      hotelCategory: priceData.hotelCategory,
      meal: priceData.meal,
      roomType: priceData.roomType,
      hotelPlaceName: priceData.hotelPlaceName,
      settlementType: priceData.settlementType,

      nightsCount: priceData.nightsCount,
      departureDate: priceData.departureDate,

      flightInsurance: insurance,

      toTrip: selectedRoundTrip.toFlight.names,
      backTrip: selectedRoundTrip.backFlight.names,

      adultsCount: adults,
      kidsCount: kids,
    };

    submitOrder(payload).then((response) => {
      if (response && response.data) {
        window.location.replace(response.data.formUrl);
      }
    });
  };

  render() {
    const { location, timeIsUpModalVisible } = this.props;
    const {
      hotelId, email, phone, adults, kids, selectedRoundTrip, insurance,
    } = location.state;

    const {
      contractInfo, isContentDisabled, priceData, currentTime, isSubmitSpinnerVisible,
      sellOnStop, paymentTest,
    } = this.state;

    const isDisabled = this.isPayDisabled();
    const btnRef = createRef();

    return (
      <div className="content" ref={(el) => { this.topRef = el; }}>
        <Helmet>
          <title>{CHECKOUT_TITLE}</title>
        </Helmet>
        <div className="container ch_modal_w">
          <div className="checkout_wrapper">
            <div className="ch_tour_desc_w">
              <img className="ch_hotel_img" src={`/i/im/${hotelId}_0_600_400_0.jpg`} alt="" />
              <div className="ch_tour_desc">
                <div className="ch_tour_desc_line1_w">
                  <div className="ch_tour_desc_line_left">
                    <div>{this.renderHotelCategory(priceData.hotelCategory)}</div>
                    <h3 className="ch_hotel_name">{priceData.hotelName}</h3>
                    <div className="ch_location">
                      {`${priceData.resort}, ${priceData.countryTo}`}
                    </div>
                    <div className="ch_tour_desc_oprator">
                      {priceData.operator}
                    </div>
                  </div>
                </div>
                <div className="ch_tour_details_w">
                  <div className="ch_details">
                    <div className="ch_details_title">Номер</div>
                    <div>
                      { priceData.roomType ? priceData.roomType : ''}
                        &nbsp;
                      {`(${priceData.hotelPlaceName ? priceData.hotelPlaceName : ''}) - ${priceData.settlementType}`}
                    </div>
                    <div className="ch_details_desc">{priceData.hotelPlaceDesc ? priceData.hotelPlaceDesc : ''}</div>
                  </div>
                  <div className="ch_details">
                    <div className="ch_details_title">Питание</div>
                    <div>{priceData.meal ? priceData.meal : ''}</div>
                  </div>
                  <div className="ch_details">
                    <div className="ch_details_title">Кто едет</div>
                    <div>{getTouristsText(adults, kids)}</div>
                    <div>{getDateAndNightsText(priceData)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ch_fligh_w">
              {(priceData && selectedRoundTrip && selectedRoundTrip.length > 0) && (
              <FlightRoundTrip
                trip={selectedRoundTrip}
                priceData={priceData}
                scrollToPayment={this.scrollToPayment}
              />
              )}
            </div>
          </div>

          {/* Contact data */}
          <div className="ch_section_w">
            <div className="ch_section_title">Контактные данные</div>
            <div className="ch_inputs_w">
              <Input label={EMAIL} value={email} disabled />
              <Input label={PHONE} value={phone} disabled />
            </div>
          </div>
          <div className="ch_section_w">
            <div className="ch_section_title">Информация о покупателе</div>
            <div className="ch_inputs_w">
              <Input
                name="lastName"
                label={LAST_NAME}
                placeholder={LAST_NAME_PH}
                value={contractInfo.lastName}
                handleValueChange={this.handleContractChange}
                validation={onlyRusLetters}
                validationError={RUS_LETTERS_ERROR}
                isValid={contractInfo.lastNameValid}
                isValidChange={this.handleContractValid}
                required
              />
              <Input
                name="firstName"
                label={FIRST_NAME}
                placeholder={FIRST_NAME_PH}
                value={contractInfo.firstName}
                handleValueChange={this.handleContractChange}
                validation={onlyRusLetters}
                validationError={RUS_LETTERS_ERROR}
                isValid={contractInfo.firstNameValid}
                isValidChange={this.handleContractValid}
                required
              />
              <Input
                name="afterName"
                label={AFTER_NANE}
                placeholder={AFTER_NAME_PH}
                value={contractInfo.afterName}
                handleValueChange={this.handleContractChange}
                validation={onlyRusLetters}
                validationError={RUS_LETTERS_ERROR}
                isValid={contractInfo.afterNameValid}
                isValidChange={this.handleContractValid}
                required
              />
              <Input
                name="birthDay"
                label={DATE_OF_BIRTH}
                placeholder={DATE_PH}
                value={contractInfo.birthDay}
                type="date"
                handleValueChange={this.handleContractChange}
                isValid={contractInfo.birthDayValid}
                isValidChange={this.handleContractValid}
                required
              />
              <div className="ch_input_wrapper">
                <div>
                  {SEX}
                  &nbsp;
                  <span className="ch_input_required">*</span>
                </div>
                <div className="ch_sex_slector_w">
                  <button
                    type="button"
                    value="male"
                    className={`ch_sex_button_firsr ${!contractInfo.sex ? 'ch_sex_btn_firt' : ''} ${contractInfo.sex === 'male' ? 'ch_sex_selected' : ''}`}
                    onClick={() => this.handleContrakctSexSelector('male')}
                  >
                    {MALE_SEX}
                  </button>
                  <button
                    type="button"
                    value="female"
                    className={`ch_sex_button_sec ${contractInfo.sex === 'female' ? 'ch_sex_selected' : ''}`}
                    onClick={() => this.handleContrakctSexSelector('female')}
                  >
                    {FEMALE_SEX}
                  </button>
                </div>
              </div>
            </div>
            <div className="ch_inputs_w">
              <Input
                name="passport"
                label={PASSPORT}
                placeholder={PASSPORT_PH}
                value={contractInfo.passport}
                handleValueChange={this.handleContractChange}
                validation={passportSeriaAndNumber}
                validationError={LAT_LETTERS_ERROR}
                isValid={contractInfo.passportValid}
                isValidChange={this.handleContractValid}
                maxLength={9}
                required
              />
              <Input
                name="passportStartDate"
                label={PASSPORT_DATE_START}
                placeholder={DATE_PH}
                value={contractInfo.passportStartDate}
                handleValueChange={this.handleContractChange}
                type="date"
                isValid={contractInfo.passportStartDateValid}
                isValidChange={this.handleContractValid}
                required
              />
              <Input
                name="passportEndDate"
                label={PASSPORT_DATE_END}
                placeholder={DATE_PH}
                value={contractInfo.passportEndDate}
                handleValueChange={this.handleContractChange}
                type="date"
                isValid={contractInfo.passportEndDateValid}
                isValidChange={this.handleContractValid}
                required
              />
            </div>
          </div>

          {/* Timer */}
          <div className="ch_section_w">
            <div className="ch_timer_w">
              {PRICE_ACTUALIZATION}
              &nbsp;
              <Timer
                time={20}
                timeIsUp={this.timeIsUp}
                currentTime={currentTime}
                currentTimeUpdate={this.currentTimeUpdate}
              />
            </div>
          </div>

          <div className="ch_section_w">
            <div className="ch_section_title">Данные туристов</div>
            {this.renderTuristsDataForm()}
          </div>
          <div className="ch_section_w">
            <div className="ch_payment_title">Информация о заказе</div>
            <div className="ch_payment_final_price">{`${priceData.originalPrice} ${priceData.currency}`}</div>
            <div className="ch_payment_final_price_byn">{`${priceData.totalPriceByn} BYN`}</div>
            <label htmlFor="insurance_check" className="checkbox_container">
              <input
                id="insurance_check"
                type="checkbox"
                onChange={this.toggleCheckbox}
                checked={insurance}
              />
              <span className="checkmark"></span>
              <div className="">
                Нужна страховка от невылета
              </div>
            </label>
            {/* Credit card */}
            {/* <div className="ch_payment_card_wrapper">
              <div className="ch_payment_card_w">
                <div className="ch_card_front">
                  <div className="ch_cards">
                    <img alt="" className="ch_cards_ico" src={mastercard} />
                  </div>
                  <div className="ch_card_number">
                    <Input
                      name="number"
                      label={CARD_NUMBER}
                      placeholder={CARD_NUMBER_PH}
                      value={creditCard.number}
                      handleValueChange={this.handleCreditCardChange}
                      validation={cardNumberValidation}
                      validationError={ONLY_NUMBERS_ERROR}
                      isValid={creditCard.numberValid}
                      isValidChange={this.handleCreditCardValid}
                      maxLength={19}
                      required
                    />
                  </div>
                  <div className="ch_card_term_w">
                    <div className="ch_card_term_label">
                      {CARD_TERM}
                      &nbsp;
                      <span className="ch_input_required">*</span>
                    </div>
                    <Input
                      name="month"
                      placeholder={CARD_TERM_MONTH_PH}
                      value={creditCard.month}
                      handleValueChange={this.handleCreditCardChange}
                      validation={onlyNumbers}
                      validationError={ONLY_NUMBERS_ERROR}
                      isValid={creditCard.monthValid}
                      isValidChange={this.handleCreditCardValid}
                      maxLength={2}
                      required
                    />
                    <Input
                      name="year"
                      placeholder={CARD_TERM_YEAR_PH}
                      value={creditCard.year}
                      handleValueChange={this.handleCreditCardChange}
                      validation={onlyNumbers}
                      validationError={ONLY_NUMBERS_ERROR}
                      isValid={creditCard.yearValid}
                      isValidChange={this.handleCreditCardValid}
                      maxLength={2}
                      required
                    />
                  </div>
                  <div className="ch_card_holder">
                    <Input
                      label={CARD_HOLDER_NAME}
                      name="holder"
                      placeholder={CARD_HOLDER_NAME_PH}
                      value={creditCard.holder}
                      handleValueChange={this.handleCreditCardChange}
                      validation={onlyLatLetters}
                      validationError={LAT_LETTERS_ERROR}
                      isValid={creditCard.holderValid}
                      isValidChange={this.handleCreditCardValid}
                      required
                    />
                  </div>
                </div>
                <div className="ch_card_back">
                  <div className="ch_card_black_line"></div>
                  <div className="ch_card_cvv">
                    <Input
                      name="cvv"
                      label={CARD_CVV}
                      value={creditCard.cvv}
                      handleValueChange={this.handleCreditCardChange}
                      validation={onlyNumbers}
                      validationError={ONLY_NUMBERS_ERROR}
                      isValid={creditCard.cvvValid}
                      isValidChange={this.handleCreditCardValid}
                      maxLength={3}
                      required
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="ch_pay_wrapper">
              <div className="ch_pay_w">
                <button
                  ref={btnRef}
                  type="button"
                  className={`btn btn__yellow ch_submit ${isDisabled ? 'btn_dis' : ''}`}
                  disabled={isDisabled}
                  onClick={() => this.handleSubmitOrder(btnRef)}
                >
                  <div>{`Оплатить ${priceData.totalPriceByn} BYN`}</div>
                  <div className="ch_submit_spinner_w">
                    <FallingLines
                      color="#003399"
                      width="30"
                      visible={isSubmitSpinnerVisible}
                      ariaLabel="falling-circles-loading"
                    />
                  </div>
                </button>
                {isDisabled && (
                  <div className="ch_fill_gaps">
                    Заполните все обязательные поля
                    &nbsp;
                    <span className="ch_input_required">*</span>
                  </div>
                )}
                {sellOnStop && (
                  <div className="ch_stop_sell_message">
                    Оплата на данный момент не доступна
                  </div>
                )}
                {paymentTest && (
                  <div className="ch_stop_sell_message">
                    Тестовый платеж
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`ch_spinner_wrapper ${isContentDisabled ? '' : 'ch_invisible'}`}>
            <div>
              <Bars
                height="40"
                width="40"
                color="#00BFFF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={isContentDisabled}
              />
            </div>
          </div>

          {/* Modal */}
          <div className={`ch_outdated_modal_w ${timeIsUpModalVisible ? '' : 'ch_invisible'}`}>
            <div className="ch_outdated_modal">
              <div className="ch_modal_text">
                Данные тура могли устареть
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn__yellow ch_modal_button"
                  onClick={() => this.onModalButtonClick()}
                >
                  Проверить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Checkout.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  changeTimeIsUpVisibility: PropTypes.func.isRequired,
  timeIsUpModalVisible: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  changeTimeIsUpVisibility
})(Checkout);
