/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { TURKEY_TITLE, TURKEY_DESCRIPTION } from '../../Utils/Constants/SEO';
import ToursResultFunc from '../ToursResult/ToursResultFunc';

class Turkey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{TURKEY_TITLE}</title>
          <meta name="description" content={TURKEY_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/turkey" />
          <meta property="og:title" content={TURKEY_TITLE} />
          <meta property="og:description" content={TURKEY_DESCRIPTION} />
          <meta property="og:image" content="https://tourhunter.by/file/turkey.jpg" />

          <link rel="canonical" href="https://tourhunter.by/turkey" />
        </Helmet>
        <ToursResultFunc />
      </div>
    );
  }
}

export default Turkey;
