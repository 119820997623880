import React from 'react';
import './css/Description.css';
import {
  DESC_TITLE, DESC1, DESC2,
} from '../../../Utils/Constants/HomePageConstants';

function Description() {
  return (
    <div className="container">
      <div className="description">
        <h1 className="blue__title">{DESC_TITLE}</h1>
        <div className="description__item__wrapper">
          <div className="description__item">
            <p>{DESC1}</p>
          </div>
          <div className="description__item">
            <p>{DESC2}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
