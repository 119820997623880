/* eslint-disable no-trailing-spaces */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet } from 'react-helmet';
import { TYRY_MINKS_TITLE, TYRY_MINKS_DESCRIPTION } from '../../Utils/Constants/SEO';
import ToursResultFunc from '../ToursResult/ToursResultFunc';

class TuryIzMinska extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="content">
        <Helmet>
          <title>{TYRY_MINKS_TITLE}</title>
          <meta name="description" content={TYRY_MINKS_DESCRIPTION} />

          <meta property="og:site_name" content="TourHunter.by" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://tourhunter.by/tury-iz-minska" />
          <meta property="og:title" content={TYRY_MINKS_TITLE} />
          <meta property="og:description" content={TYRY_MINKS_DESCRIPTION} />
          <meta property="og:image" content="https://tourhunter.by/file/turkey.jpg" />

          <link rel="canonical" href="https://tourhunter.by/tury-iz-minska" />
        </Helmet>
        <ToursResultFunc />
      </div>
    );
  }
}

export default TuryIzMinska;
